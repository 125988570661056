// These vars come from Netlify, but you can override them in .env
export const API_URL = process.env.NEXT_PUBLIC_API_URL || "";
export const GOOGLE_TRACKING_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID || "";
export const MOCK_API = process.env.NEXT_PUBLIC_MOCK_API || "false";
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || "";
export const NODE_ENV = process.env.NODE_ENV || "";
export const COINBASE_COMMERCE_ID =
  process.env.NEXT_PUBLIC_COINBASE_COMMERCE_ID || "";
export const RIPPLE_WALLET_ID = process.env.NEXT_PUBLIC_RIPPLE_WALLET_ID || "";
export const RIPPLE_DESTINATION_TAG =
  process.env.NEXT_PUBLIC_RIPPLE_DESTINATION_TAG || "";
export const ZEC_WALLET_ID = process.env.NEXT_PUBLIC_ZEC_WALLET_ID || "";
export const ALGOLIA_ADMIN_API_KEY =
  process.env.NEXT_PUBLIC_ALGOLIA_ADMIN_API_KEY ?? "";
export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? "";
export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY ?? "";
